import React from "react";
import { Category } from "./category";
import { DragDropContext, DropResult, Droppable } from "@hello-pangea/dnd";

import type { RootState } from "store";
import { useSelector } from "react-redux";
import { createChange } from "create_change";
import { Item, Category as CategoryType } from "types";

export const Categories = () => {
  const checklist = useSelector((state: RootState) => state.checklist.value);
  const categories = checklist.categories;

  // make sure that we don't insert unchecked items after checked and vice-versa
  const ensureOrderByChecked = (
    requestedIndex: number,
    item: Item,
    newCategory: CategoryType,
  ) => {
    const indexOfFirstCheckedItem = newCategory.items
      .filter((_) => !_.checked)
      .filter((_) => _.id != item.id).length;

    const droppedAfterFirstCheckedItems =
      requestedIndex >= indexOfFirstCheckedItem;

    return item.checked !== droppedAfterFirstCheckedItems
      ? indexOfFirstCheckedItem
      : requestedIndex;
  };

  const moveItem = (
    itemId: string,
    oldIndex: number,
    newIndex: number,
    newCategoryId: string,
  ) => {
    const item = categories
      .flatMap(({ items }) => items)
      .find((item) => item.id === itemId)!;
    const newCategory = categories.find((_) => _.id === newCategoryId)!;

    const actualNewIndex = ensureOrderByChecked(newIndex, item, newCategory);

    // don't create a change if an item does not move anywhere
    if (newCategoryId === item.category_id && oldIndex === actualNewIndex) {
      return;
    }

    createChange({
      name: "moveItem",
      payload: {
        itemId: item.id,
        itemName: item.name,
        oldCategoryName: item.category,
        newCategoryName: newCategory.name,
        oldItemPosition: oldIndex + 1,
        newItemPosition: actualNewIndex + 1,
      },
    });
  };
  const moveCategory = (
    categoryId: string,
    oldIndex: number,
    newIndex: number,
  ) => {
    // don't create a change if an item does not move anywhere
    if (oldIndex === newIndex) {
      return;
    }

    const categoryName = categories.find((_) => _.id === categoryId)!.name;

    createChange({
      name: "moveCategory",
      payload: {
        categoryName: categoryName,
        oldPosition: oldIndex + 1,
        newPosition: newIndex + 1,
      },
    });
  };

  const onDragEnd = function (result: DropResult) {
    const { draggableId, source, destination, type } = result;

    if (!destination) {
      return;
    }

    if (type === "item") {
      moveItem(
        draggableId,
        source.index,
        destination.index,
        destination.droppableId,
      );
    } else {
      moveCategory(draggableId, source.index, destination.index);
    }
  };

  const usesCategories = categories.length > 1 || categories[0]?.name !== "";
  const categoriesList = categories.map((category, index) => (
    <Category
      usesCategories={usesCategories}
      category={category}
      isTemplate={checklist.is_template}
      authorization={checklist.authorization}
      index={index}
      key={category.id}
    />
  ));

  return (
    <>
      <hr />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="all-categories" type="category">
          {(provided) => (
            <div
              id="checklist-categories-and-items"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {categoriesList}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
