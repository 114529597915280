import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemForm, Item } from "types";

export interface ItemFormState {
  value: ItemForm;
}

const initialState: ItemFormState = {
  value: {
    isNewItemMode: false,
    isEditItemMode: false,
    itemToEdit: null,
  },
};

const itemFormSlice = createSlice({
  name: "itemForm",
  initialState,
  reducers: {
    enableNewItemMode(state) {
      state.value.isNewItemMode = true;
    },
    closeItemForm(state) {
      state.value.isNewItemMode = false;
      state.value.isEditItemMode = false;
      state.value.itemToEdit = null;
    },
    enableEditItemMode(state, action: PayloadAction<Item>) {
      state.value.isEditItemMode = true;
      state.value.itemToEdit = action.payload;
    },
  },
});

export const { enableNewItemMode, closeItemForm, enableEditItemMode } =
  itemFormSlice.actions;
export default itemFormSlice.reducer;
