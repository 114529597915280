import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  Checklist,
  ChangeBase,
  DeleteCategoryChange,
  RenameCategoryChange,
  DeleteItemChange,
  MoveItemChange,
  MoveCategoryChange,
  CreateItemChange,
  CheckUncheckItemChange,
  Change,
  UpdateItemChange,
} from "types";

export interface ChecklistState {
  value: Checklist;
  loading: boolean;
}

declare const window: {
  checklist: Checklist;
} & Window;

const initialState: ChecklistState = {
  value: window.checklist,
  loading: true,
};

export const checklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    deleteCategory: (
      state,
      action: PayloadAction<ChangeBase & DeleteCategoryChange>,
    ) => {
      state.value.categories = allCategoriesExcept(
        state,
        action.payload.payload.categoryName,
      );

      state.value.changes.unshift(action.payload);
    },
    renameCategory: (
      state,
      action: PayloadAction<ChangeBase & RenameCategoryChange>,
    ) => {
      const { oldCategoryName, newCategoryName } = action.payload.payload;
      const category = findCategoryByName(state, oldCategoryName)!;

      const existingCategoryWithTheSameName = findCategoryByName(
        state,
        newCategoryName,
      );

      if (existingCategoryWithTheSameName) {
        existingCategoryWithTheSameName.items.push(...category.items);
        category.items.forEach((item) => {
          item.category = newCategoryName;
          item.category_id = existingCategoryWithTheSameName.id;
        });
        state.value.categories = allCategoriesExcept(state, oldCategoryName);
      } else {
        category.name = newCategoryName;
        category.items.forEach((item) => {
          item.category = newCategoryName;
        });
      }

      state.value.changes.unshift(action.payload);
    },
    deleteItem: (
      state,
      action: PayloadAction<ChangeBase & DeleteItemChange>,
    ) => {
      const itemId = action.payload.payload.itemId;

      const category = findCategoryByItemId(state, itemId)!;

      category.items = category.items.filter((item) => item.id != itemId);

      if (category.items.length == 0) {
        state.value.categories = allCategoriesExcept(state, category.name);
      }

      state.value.changes.unshift(action.payload);
    },
    checkUncheckItem: (
      state,
      action: PayloadAction<ChangeBase & CheckUncheckItemChange>,
    ) => {
      const { itemId, checked } = action.payload.payload;

      const item = findItem(state, itemId)!;

      const category = findCategoryByItemId(state, itemId)!;

      item.checked = checked;

      const uncheckedItems = category.items.filter((_) => !_.checked);
      const checkedItems = category.items.filter((_) => _.checked);

      category.items = [...uncheckedItems, ...checkedItems];

      state.value.changes.unshift(action.payload);
    },
    moveItem: (state, action: PayloadAction<ChangeBase & MoveItemChange>) => {
      const { itemId, oldCategoryName, newCategoryName, newItemPosition } =
        action.payload.payload;

      const item = findItem(state, itemId)!;

      const oldCategory = findCategoryByName(state, oldCategoryName)!;
      const newCategory = findCategoryByName(state, newCategoryName)!;

      item.category = newCategoryName;
      item.category_id = newCategory.id;

      // 1. delete item from old category
      oldCategory.items = oldCategory.items.filter((_) => _.id != item.id);

      // 2. insert item into the new category in new position
      newCategory.items.splice(newItemPosition - 1, 0, item);

      if (oldCategory.items.length === 0) {
        state.value.categories = allCategoriesExcept(state, oldCategoryName);
      }

      state.value.changes.unshift(action.payload);
    },
    moveCategory: (
      state,
      action: PayloadAction<ChangeBase & MoveCategoryChange>,
    ) => {
      const { categoryName, newPosition } = action.payload.payload;

      const category = findCategoryByName(state, categoryName)!;

      state.value.categories = allCategoriesExcept(state, categoryName);

      state.value.categories.splice(newPosition - 1, 0, category);

      state.value.changes.unshift(action.payload);
    },
    createItem: (
      state,
      action: PayloadAction<ChangeBase & CreateItemChange>,
    ) => {
      const { itemId, itemName, categoryName, description } =
        action.payload.payload;

      let category = findCategoryByName(state, categoryName);

      const categoryId = category?.id || crypto.randomUUID();

      if (!category) {
        category = {
          id: categoryId,
          name: categoryName,
          items: [],
        };

        state.value.categories.unshift(category);
      }

      category.items.unshift({
        id: itemId,
        name: itemName,
        category: categoryName,
        category_id: categoryId,
        description: description,
        checked: false,
      });

      state.value.changes.unshift(action.payload);
    },
    checklistLoaded: (state, action: PayloadAction<Checklist>) => {
      state.value = action.payload;
      state.loading = false;
    },
    markChangeAsSynced: (state, action: PayloadAction<Change>) => {
      const change = state.value.changes.find(
        (_) => (_.id = action.payload.id),
      )!;

      change.synced_to_server = true;
    },
    updateItem: (
      state,
      action: PayloadAction<ChangeBase & UpdateItemChange>,
    ) => {
      const { itemId, newName, category, description } = action.payload.payload;
      const item = findItem(state, itemId)!;

      // update name and description
      if (newName != undefined) {
        item.name = newName;
      }
      if (description) {
        item.description = description.new;
      }

      // update category
      if (category) {
        const oldCategory = findCategoryByItemId(state, itemId)!;

        oldCategory.items = oldCategory.items.filter((_) => _.id != itemId);

        if (oldCategory.items.length == 0) {
          state.value.categories = allCategoriesExcept(state, category.old);
        }

        let newCategory = findCategoryByName(state, category.new);

        const categoryId = newCategory?.id || crypto.randomUUID();

        const newItem = {
          id: itemId,
          name: item.name,
          position: 0,
          checked: item.checked,
          category: category.new,
          category_id: categoryId,
          description: item.description,
        };

        if (newCategory) {
          newCategory.items.unshift(newItem);
        } else {
          newCategory = {
            id: categoryId,
            name: category.new,
            items: [newItem],
          };
          state.value.categories.unshift(newCategory);
        }
      }

      state.value.changes.unshift(action.payload);
    },
  },
});

export const {
  deleteCategory,
  renameCategory,
  deleteItem,
  checkUncheckItem,
  moveItem,
  moveCategory,
  createItem,
  checklistLoaded,
  markChangeAsSynced,
  updateItem,
} = checklistSlice.actions;

export const actions = checklistSlice.actions;

export default checklistSlice.reducer;

const findCategoryByName = (state: ChecklistState, categoryName: string) => {
  return state.value.categories.find(
    (_) => _.name === categoryName || (_.name === null && categoryName === ""),
  );
};

const findCategoryByItemId = (state: ChecklistState, itemId: string) => {
  return state.value.categories.find((category) =>
    category.items.find((_) => _.id === itemId),
  );
};

const findItem = (state: ChecklistState, itemId: string) => {
  return state.value.categories
    .flatMap((_) => _.items)
    .find((_) => _.id === itemId);
};

const allCategoriesExcept = (state: ChecklistState, categoryName: string) => {
  return state.value.categories.filter((_) => _.name !== categoryName);
};
