type UrlParams = Record<string, string>;

const csrfToken =
  document.querySelector("[name='csrf-token']")?.getAttribute("content") || "";

if (!csrfToken) {
  throw new Error(`no CSRF token found`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function fetchWithBody(method: string, url: string, body: any) {
  const requestOptions: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(url, requestOptions);
  return response.json();
}

async function fetchWithUrlParams(
  method: string,
  url: string,
  params: UrlParams,
) {
  const requestOptions: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  };

  const parameters = new URLSearchParams(params);
  url = url + (parameters.toString() ? `?${parameters.toString()}` : "");
  const response = await fetch(url, requestOptions);
  return response.json();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function postRequest(url: string, body: any) {
  return fetchWithBody("POST", url, body);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function putRequest(url: string, body: any) {
  return fetchWithBody("PUT", url, body);
}

export async function deleteRequest(url: string, params: UrlParams = {}) {
  return fetchWithUrlParams("DELETE", url, params);
}

export async function getRequest(url: string, params: UrlParams = {}) {
  return fetchWithUrlParams("GET", url, params);
}

export function currentTimeUnixTimestamp() {
  return Math.floor(Date.now() / 1000);
}

export function unixTimestampToDate(unixTimestamp: number) {
  return new Date(unixTimestamp * 1000);
}

export function onPageLoad(callback: () => void) {
  if (document.readyState === "loading") {
    // Loading hasn't finished yet
    document.addEventListener("DOMContentLoaded", callback);
  } else {
    // `DOMContentLoaded` has already fired
    callback();
  }
}
