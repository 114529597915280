import { store } from "store";
import { Change, Checklist } from "types";
import { generateAction } from "create_change";
import { createConsumer } from "@rails/actioncable";
import { postRequest } from "helpers";
import { checklistLoaded } from "redux/slices/checklistSlice";

export const initActionCable = () => {
  createConsumer().subscriptions.create(
    {
      channel: "ChecklistsChannel",
      checklist_id: getChecklist().id,
      newest_synced_change_id: getChecklist().newest_synced_change?.id,
    },
    {
      received(data: Change[] | Change) {
        // if we received an array from backend then this is a sync of missed changes after reconnect
        if (Array.isArray(data)) {
          if (data.length > 0 || unsyncedChanges().length > 0) {
            fullSync();
          }
        } else {
          applyChange(data);
        }
      },
    },
  );
};

const getChecklist = () => {
  return store.getState().checklist.value;
};

const fullSync = () => {
  postRequest(window.location.pathname + "/sync", {
    changes: unsyncedChanges(),
  }).then((checklist: Checklist) => {
    store.dispatch(checklistLoaded(checklist));
  });
};

const unsyncedChanges = () => {
  return getChecklist()
    .changes.filter((_) => !_.synced_to_server)
    .reverse();
};

export const applyChange = (newChange: Change) => {
  const existingChange = getChecklist().changes.find(
    (_) => _.id === newChange.id,
  );
  if (
    !existingChange &&
    newChange.created_at >
      (getChecklist().newest_synced_change?.created_at || 0)
  ) {
    store.dispatch(generateAction(newChange));
  }
};
