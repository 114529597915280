import React from "react";
import { Item as ItemType, Authorization } from "types";
import { useSelector } from "react-redux";
import type { RootState } from "store";
import { createChange } from "create_change";
import { Draggable } from "@hello-pangea/dnd";
import { useDispatch } from "react-redux";
import { enableEditItemMode as enableEditItemModeAction } from "redux/slices/itemFormSlice";

export const Item = ({
  item,
  authorization,
  isTemplate,
  index,
}: {
  item: ItemType;
  authorization: Authorization;
  isTemplate: boolean;
  index: number;
}) => {
  const { isSuggestionMode } = useSelector(
    (state: RootState) => state.suggestion.value,
  );
  const canEditItem = authorization.can_edit_item || isSuggestionMode;
  const dispatch = useDispatch();

  function enableEditItemMode() {
    dispatch(enableEditItemModeAction(item));
  }

  const checkItem = (item: ItemType) => {
    createChange({
      name: "checkUncheckItem",
      payload: {
        itemId: item.id,
        itemName: item.name,
        checked: !item.checked,
      },
    });
  };

  const checkboxTag = () => {
    if (!isTemplate && authorization.can_edit_item) {
      return (
        <>
          <input
            type="checkbox"
            name="check"
            id={`check-${item.id}`}
            checked={item.checked}
            className="form-check-input me-1 js-item-check"
            data-item-id={item.id}
            onChange={() => {
              checkItem(item);
            }}
          ></input>
        </>
      );
    }
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <li
          className="list-group-item"
          id={`item-${item.id}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="item-group">
            <div className="item-group-checkbox-container">{checkboxTag()}</div>
            <div>
              <div {...(canEditItem ? provided.dragHandleProps : {})}>
                {canEditItem ? (
                  <a
                    onClick={enableEditItemMode}
                    href="#"
                    className="link-dark text-decoration-none text-break"
                    id={`edit-item-${item.id}`}
                    aria-label="Edit item"
                  >
                    {item.name || <i className="bi-pencil-square"></i>}
                  </a>
                ) : (
                  item.name
                )}
              </div>
              <div>
                <small>{item.description}</small>
              </div>
            </div>
          </div>
        </li>
      )}
    </Draggable>
  );
};
