import { Change } from "types";
import { PartialChange } from "types";
import { currentTimeUnixTimestamp } from "helpers";
import {
  deleteCategory,
  deleteItem,
  moveItem,
  moveCategory,
  renameCategory,
  createItem,
  checkUncheckItem,
  markChangeAsSynced,
  updateItem,
} from "redux/slices/checklistSlice";
import { postRequest } from "helpers";
import { store } from "store";

declare global {
  interface Window {
    currentUser: { username: string };
  }
}

export async function createChange(partialChange: PartialChange) {
  const state = store.getState();

  const change: Change = {
    ...partialChange,
    id: crypto.randomUUID(),
    author_username: window.currentUser.username,
    created_at: currentTimeUnixTimestamp(),
    checklist_id: state.checklist.value.id,
    suggestion_id: state.suggestion.value.suggestionId,
    synced_to_server: false,
  };

  store.dispatch(generateAction(change));

  if (!change.suggestion_id) {
    postRequest(window.location.pathname + "/changes", change).then(() => {
      store.dispatch(markChangeAsSynced(change));
    });
  }
}

export function generateAction(change: Change) {
  switch (change.name) {
    case "deleteCategory":
      return deleteCategory(change);
    case "renameCategory":
      return renameCategory(change);
    case "deleteItem":
      return deleteItem(change);
    case "moveItem":
      return moveItem(change);
    case "moveCategory":
      return moveCategory(change);
    case "createItem":
      return createItem(change);
    case "checkUncheckItem":
      return checkUncheckItem(change);
    case "updateItem":
      return updateItem(change);
  }
}
