import React, { useState, FormEvent, SyntheticEvent, useEffect } from "react";
import type { RootState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { createChange } from "create_change";
import { closeItemForm as closeItemFormAction } from "redux/slices/itemFormSlice";
import { Item, UpdateItemChangePayload } from "types";

export const ItemForm = () => {
  const itemToEdit = useSelector(
    (state: RootState) => state.itemForm.value.itemToEdit,
  );
  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (itemToEdit) {
      setName(itemToEdit.name);
      setCategory(itemToEdit.category);
      setDescription(itemToEdit.description);
    }
  }, [itemToEdit?.id]);

  const checklist = useSelector((state: RootState) => state.checklist.value);
  const dispatch = useDispatch();

  const categoriesList = checklist.categories.map((category) => (
    <option value={category.name} key={category.id}></option>
  ));

  function closeItemForm(event: SyntheticEvent) {
    event.preventDefault();
    dispatch(closeItemFormAction());
  }

  const updateItem = (item: Item) => {
    const payload: UpdateItemChangePayload = {
      itemId: item.id,
      name: item.name,
    };

    if (name !== item.name) {
      payload.newName = name;
    }

    if (category.trim() !== item.category) {
      payload.category = { old: item.category, new: category.trim() };
    }

    if (description.trim() !== item.description) {
      payload.description = { old: item.description, new: description.trim() };
    }

    return createChange({
      name: "updateItem",
      payload: payload,
    }).then(() => {
      setCategory("");
      dispatch(closeItemFormAction());
    });
  };

  const createItem = () => {
    return createChange({
      name: "createItem",
      payload: {
        itemId: crypto.randomUUID(),
        itemName: name,
        categoryName: category.trim(),
        description: description.trim(),
      },
    });
  };

  const deleteChecklistItem = (event: SyntheticEvent) => {
    event.preventDefault();
    if (itemToEdit) {
      createChange({
        name: "deleteItem",
        payload: {
          itemId: itemToEdit.id,
          itemName: itemToEdit.name,
          categoryName: itemToEdit.category,
        },
      });
    }
    dispatch(closeItemFormAction());
  };

  const handleSubmit = (event: FormEvent) => {
    setDisabled(true);
    event.preventDefault();

    (itemToEdit ? updateItem(itemToEdit) : createItem())
      .then(() => {
        setName("");
        setDescription("");
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const buttonText = itemToEdit ? "Edit Item" : "Add Item";

  return (
    <>
      <form id="item_form" className="item-form p-1" onSubmit={handleSubmit}>
        {itemToEdit && (
          <a
            onClick={deleteChecklistItem}
            title="Delete item"
            href="#"
            className="me-1"
          >
            <i
              className="bi-trash text-danger"
              style={{ fontSize: "1.4rem" }}
            ></i>
          </a>
        )}
        <button
          type="button"
          className="btn-close float-end"
          value="Close"
          aria-label="Dismiss"
          onClick={closeItemForm}
        ></button>
        <input
          className="form-control mb-3"
          type="text"
          name="name"
          id="item_name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={disabled}
          autoFocus
          placeholder={"Name"}
          autoComplete="off"
        />
        <input
          type="text"
          name="category"
          id="category"
          className="form-control mb-3"
          value={category}
          list="existing-categories"
          onChange={(e) => setCategory(e.target.value)}
          disabled={disabled}
          placeholder={"Category"}
        />
        <datalist id="existing-categories">{categoriesList}</datalist>
        <textarea
          className="form-control mb-3"
          name="description"
          id="item_description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          disabled={disabled}
          placeholder={"Description"}
          autoComplete="off"
          maxLength={2048}
        />
        <input
          type="submit"
          name="commit"
          value={buttonText}
          className="btn btn-primary"
          disabled={disabled}
          style={{ width: "100%" }}
        />
      </form>
    </>
  );
};
