import { initSaveAllChangesWithSuggestionHandler } from "init_save_all_changes_with_suggestion_handler";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disableSuggestionMode } from "redux/slices/suggestionSlice";
import { RootState } from "store";

export const SuggestionBar = () => {
  const checklist = useSelector((state: RootState) => state.checklist.value);
  const [isMobile, setIsMobile] = useState(false);
  const suggestionId = useSelector(
    (state: RootState) => state.suggestion.value.suggestionId,
  );
  const dispatch = useDispatch();

  if (!suggestionId) {
    throw new Error("No suggestion id found");
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function cancelSuggestion() {
    dispatch(disableSuggestionMode());
  }

  return (
    <>
      <button
        id="cancel-suggestion-button"
        className="btn btn-danger"
        onClick={cancelSuggestion}
        style={{
          position: "fixed",
          top: "16px",
          right: isMobile ? "135px" : "276px",
          zIndex: 1000,
        }}
      >
        <i className="bi bi-x-lg"></i>
        {!isMobile && <>&nbsp;Cancel</>}
      </button>
      <button
        id="submit-suggestion-button"
        className="btn btn-success"
        onClick={initSaveAllChangesWithSuggestionHandler(
          checklist.id,
          checklist.changes,
          suggestionId,
        )}
        style={{
          position: "fixed",
          top: "16px",
          right: "84px",
          zIndex: 1000,
        }}
      >
        <i className="bi bi-brush"></i>
        {!isMobile && <>&nbsp;Submit Suggestion</>}
      </button>
    </>
  );
};
