import { configureStore } from "@reduxjs/toolkit";
import checklistReducer from "redux/slices/checklistSlice";
import suggestionReducer from "redux/slices/suggestionSlice";
import itemFormReducer from "redux/slices/itemFormSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: window.location.pathname,
  storage,
};

const persistedChecklistReducer = persistReducer(
  persistConfig,
  checklistReducer,
);

export const store = configureStore({
  reducer: {
    checklist: persistedChecklistReducer,
    suggestion: suggestionReducer,
    itemForm: itemFormReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
