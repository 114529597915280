import React from "react";
import { Categories } from "./categories";
import { NewItemButton } from "./new_item_button";
import { Changes } from "./changes";
import type { RootState } from "store";
import { useSelector } from "react-redux";
import { SuggestionBar } from "./suggestion_bar";
import { ItemForm } from "./item_form";

export const ChecklistRoot = () => {
  const checklist = useSelector((state: RootState) => state.checklist.value);
  const loading = useSelector((state: RootState) => state.checklist.loading);
  const isSuggestionMode = useSelector(
    (state: RootState) => state.suggestion.value.isSuggestionMode,
  );
  const isItemFormOpen = useSelector(
    (state: RootState) =>
      state.itemForm.value.isNewItemMode || state.itemForm.value.isEditItemMode,
  );

  if (loading) {
    return <></>;
  }

  const canCreateItem =
    checklist.authorization.can_create_item || isSuggestionMode;

  return (
    <>
      <Categories />
      {isSuggestionMode && <Changes />}
      {isSuggestionMode && <SuggestionBar />}
      {canCreateItem && !isItemFormOpen && <NewItemButton />}
      {isItemFormOpen && <ItemForm />}
    </>
  );
};
