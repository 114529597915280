import { postRequest } from "helpers";
import { Checklist } from "types";
import { initActionCable } from "channels/checklist_channel";
import { store } from "store";
import { checklistLoaded } from "redux/slices/checklistSlice";

const REDUX_PERSIST_LOCALSTORAGE_KEY = `persist:${window.location.pathname}`;

export const performSyncOnPageLoad = () => {
  const cachedChecklist = getCachedChecklist();

  const unsyncedChanges = cachedChecklist
    ? cachedChecklist.changes.filter((_) => !_.synced_to_server).reverse()
    : [];

  postRequest(window.location.pathname + "/sync", {
    changes: unsyncedChanges,
  })
    .then((checklist: Checklist) => {
      store.dispatch(checklistLoaded(checklist));
    })
    .finally(initActionCable);
};

function getCachedChecklist(): Checklist | undefined {
  const persistedReduxStore = localStorage.getItem(
    REDUX_PERSIST_LOCALSTORAGE_KEY,
  );

  if (persistedReduxStore) {
    const persistedValue = JSON.parse(persistedReduxStore).value;
    if (persistedValue) {
      return JSON.parse(persistedValue);
    }
  }
}
