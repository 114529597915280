import React from "react";
import { enableNewItemMode as enableNewItemModeAction } from "redux/slices/itemFormSlice";
import { useDispatch } from "react-redux";

export const NewItemButton = () => {
  const dispatch = useDispatch();

  function enableNewItemMode() {
    dispatch(enableNewItemModeAction());
  }

  return (
    <button
      className="btn btn-primary new-item-button"
      onClick={enableNewItemMode}
    >
      Add Item
    </button>
  );
};
