import { enableSuggestionMode } from "redux/slices/suggestionSlice";
import { store } from "store";
import { onPageLoad } from "helpers";

onPageLoad(initializeSuggestionButton);

function initializeSuggestionButton() {
  const enableSuggestionModeButton = document.getElementById(
    "js-enable-suggestion-mode-button",
  ) as HTMLInputElement;
  if (!enableSuggestionModeButton) {
    return;
  }

  enableSuggestionModeButton.addEventListener("click", (e) => {
    e.preventDefault();

    store.dispatch(enableSuggestionMode());
  });
}
