import { onPageLoad } from "helpers";

onPageLoad(hideFlashMessages);

function hideFlashMessages() {
  const flashMessages =
    document.querySelectorAll<HTMLElement>(".alert-dismissible");
  flashMessages.forEach((flash) => {
    setTimeout(() => {
      flash.style.display = "none";
    }, 5000);
  });
}
