import React, { useState, SyntheticEvent } from "react";
import { Items } from "./items";
import { Category as CategoryType, Authorization } from "types";
import { useSelector } from "react-redux";
import type { RootState } from "store";
import { createChange } from "create_change";
import {
  Draggable,
  Droppable,
  DraggableProvidedDragHandleProps,
} from "@hello-pangea/dnd";

export const Category = (props: {
  category: CategoryType;
  authorization: Authorization;
  usesCategories: boolean;
  isTemplate: boolean;
  index: number;
}) => {
  const showMode = "showMode";
  const editNameMode = "editNameMode";
  const [mode, setMode] = useState(showMode);
  const [newName, setNewName] = useState("");
  const { isSuggestionMode } = useSelector(
    (state: RootState) => state.suggestion.value,
  );

  const canEditCategory = () => {
    return props.authorization.can_edit_category || isSuggestionMode;
  };

  const canDestroyCategory = () => {
    return props.authorization.can_destroy_category || isSuggestionMode;
  };

  const editLink = () => {
    if (canEditCategory()) {
      return (
        <a href="#" title="Edit category" onClick={enableEditNameMode}>
          <i className="bi-pencil-square"></i>
        </a>
      );
    }
  };

  const deleteCategoryRequest = (event: SyntheticEvent) => {
    event.preventDefault();
    createChange({
      name: "deleteCategory",
      payload: {
        categoryName: props.category.name,
      },
    });
  };

  const updateCategoryName = (event: SyntheticEvent) => {
    event.preventDefault();

    createChange({
      name: "renameCategory",
      payload: {
        oldCategoryName: props.category.name,
        newCategoryName: newName.trim(),
      },
    }).then(enableShowMode);
  };

  const destroyLink = () => {
    if (canDestroyCategory()) {
      return (
        <a
          onClick={deleteCategoryRequest}
          href="#"
          title="Delete category"
          style={{ fontSize: "1.5rem" }}
        >
          <i className="bi-trash"></i>
        </a>
      );
    }
  };

  const enableShowMode = () => {
    setMode(showMode);
  };

  const enableEditNameMode = (event: SyntheticEvent) => {
    event.preventDefault();

    setNewName(props.category.name);
    setMode(editNameMode);
  };

  const showView = (
    dragHandleProps: DraggableProvidedDragHandleProps | null,
  ) => {
    return (
      <h4
        id={`category-${props.category.id}`}
        {...dragHandleProps}
        className="text-break"
      >
        {props.category.name === "" ? "other" : props.category.name}
        {editLink()}
      </h4>
    );
  };

  const editNameView = () => {
    return (
      <form onSubmit={updateCategoryName}>
        <div className="row mb-2 g-0" id={`category-${props.category.id}`}>
          <div className="col-auto">
            <input
              className="form-control"
              type="text"
              id="category-name"
              name="category-name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <a
              onClick={
                props.category.name === newName
                  ? enableShowMode
                  : updateCategoryName
              }
              title="Save"
              href="#"
            >
              <i
                className="bi-check text-success"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            <a onClick={enableShowMode} title="Cancel editing" href="#">
              <i
                className="bi-x text-warning"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            {destroyLink()}
          </div>
        </div>
      </form>
    );
  };

  return (
    <Draggable draggableId={props.category.id} index={props.index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          {props.usesCategories &&
            mode === showMode &&
            showView(provided.dragHandleProps)}
          {props.usesCategories && mode === editNameMode && editNameView()}
          <Droppable droppableId={props.category.id} type="item">
            {(provided) => (
              <Items
                items={props.category.items}
                categoryId={props.category.id}
                isTemplate={props.isTemplate}
                authorization={props.authorization}
                droppableRef={provided.innerRef}
                {...provided.droppableProps}
                dragNDropPlaceholder={provided.placeholder}
              />
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};
